<template>
  <div>
    <b-card-actions title="Filters" action-collapse>
      <div>
        <b-row class="mb-1">
          <b-col md="6" lg="4" xs="12">
            <b-form-input
              v-model.lazy="searchTerm"
              v-on:keyup="onSearch"
              placeholder="Title"
              type="text"
              class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
            />
          </b-col>
          <b-col md="6" lg="4" xs="12">
            <v-select
              id="type"
              placeholder="Select Recipient"
              v-model="filterNoticeTypeValue"
              :options="noticeRecieverOptoins"
              :reduce="(option) => option.value"
              v-on:input="filterNoticeByType"
              label="name"
              class="mr-sm-1 mb-1 mb-sm-0 custom-font"
            />
          </b-col>

          <b-col md="6" lg="4" xs="12">
            <flat-pickr
              v-model="filterRangeDate"
              v-on:input="filterByDate"
              class="form-control"
              placeholder="Enter Date Range"
              :config="{
                mode: 'range',
                defaultDate: [this.filterStartDate, this.filterEndDate],
              }"
            />
          </b-col>
        </b-row>
      </div>
    </b-card-actions>

    <b-card>
      <div>
        <!-- search input -->
        <div
          class="custom-search d-flex align-items-center justify-content-end"
        >
          <div
            class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
          >
            <template v-if="$permissionAbility(NOTICE_CREATE, permissions)">
              <b-button
                class="flex-shrink-0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                v-on:click="showModal"
              >
                Publish New Notice
              </b-button>
            </template>
          </div>
        </div>

        <!-- table -->
        <vue-good-table
          styleClass="vgt-table striped table-custom-style"
          :rows="rows"
          :columns="columns"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :isLoading.sync="isLoading"
          :sort-options="{
            enabled: true,
            multipleColumns: true,
            initialSortBy: [{ field: 'created_at', type: 'desc' }],
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Notice Type -->
            <template v-if="props?.column?.field === 'format_title'">
              <span>
                <b-link v-on:click="onShowPage(props.row.id)">
                  {{ props?.row?.title }}
                </b-link>
              </span>
            </template>
            <!-- Notice Type -->
            <template v-if="props?.column?.field === 'format_type'">
              <span>{{
                noticeRecieverOptoins[props?.row?.type - 1]?.name
              }}</span>
            </template>

            <!-- email -->
            <template
              v-if="props?.column?.field === 'format_total_notify_users'"
            >
              <b-badge pill variant="light-primary">
                <template v-if="props?.row?.total_notify_users === 0">
                  <span>Sending....</span></template
                >
                <template v-else-if="props?.row?.total_notify_users > 0"
                  ><span>{{ props?.row?.total_notify_users }}</span></template
                >
              </b-badge>
            </template>
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <template
                v-if="$permissionAbility(NOTICE_DETAILS_ACCESS, permissions)"
              >
                <span @click="onShowPage(props.row.id)">
                  <feather-icon
                    v-b-tooltip.hover
                    icon="EyeIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="View"
                    size="16"
                  />
                </span>
              </template>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10', '25', '50', '100', '500']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>

      <b-modal
        id="modal-notice-form"
        centered
        :title="'Publish Notice'"
        hide-footer
        @hidden="hiddenModal"
        no-close-on-backdrop
        size="lg"
      >
        <validation-observer ref="noticeValidation">
          <b-form v-on:submit.prevent="validationForm">
            <!-- Notice Title -->
            <b-form-group
              label="Notice Title"
              label-for="title"
              class="required-label"
            >
              <validation-provider
                #default="{ errors }"
                name="title"
                vid="title"
              >
                <b-form-input
                  type="text"
                  v-model="title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Notice Title"
                  rules="required|max:255"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- select notice reciever -->
            <b-form-group
              label="Recipient Type"
              label-for="type"
              class="required-label"
            >
              <ValidationProvider
                name="recipient"
                v-slot="{ errors }"
                vid="type"
              >
                <v-select
                  id="type"
                  placeholder="Select Recipient Type"
                  v-model="noticeRecieverTypeValue"
                  :options="noticeRecieverOptoins"
                  :reduce="(option) => option.value"
                  v-on:input="setSelectedRecieverType"
                  label="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>

            <template v-if="searchInputType == 'custom'">
              <b-form-group
                label="Reciever Email "
                label-for="custom_reciever"
                class="required-label"
              >
                <ValidationProvider
                  name="reciever email"
                  v-slot="{ errors }"
                  vid="custom_reciever"
                  rules="email|required"
                >
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    taggable
                    push-tags
                    placeholder="Type Here..."
                    label="title"
                    v-model="customRecievers"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </template>
            <!-- Select noitce reciever -->
            <template v-if="searchInputType == 'defined'">
              <b-form-group
                label="Recipient"
                label-for="reciever"
                class="required-label"
              >
                <ValidationProvider
                  name="reciever"
                  v-slot="{ errors }"
                  vid="reciever"
                  rules="required"
                >
                  <v-select
                    v-model="recievers"
                    multiple
                    :options="recieverIdOption"
                    :reduce="(dept) => dept.id"
                    label="name"
                    placeholder="Search Here..."
                    :disabled="recieverIdOption.length > 0 ? false : true"
                    :close-on-select="false"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </template>

            <b-form-group label="Except Employee" label-for="except_employee">
              <ValidationProvider name="except_employee" vid="except_employee">
                <v-select
                  id="except_employee"
                  placeholder="Select Except Employee"
                  v-model="exceptEmployeeList"
                  :options="exceptEmployeeOptions"
                  :reduce="(item) => item?.id"
                  label="name"
                  multiple
                  :close-on-select="false"
                >
                      <template #option="data">
                        <div class="d-flex align-items-center">
                          <b-avatar :src="data?.avatar === '' ? '/avatar.svg' : data?.avatar" class="mr-1">
                          </b-avatar>
                          <div>
                            <div class="font-weight-bolder">
                              {{ data?.name }}
                              <b-badge pill variant="warning">{{
                                  data?.employee_number
                                }}</b-badge>
                            </div>
                            <div class="font-small-2">
                              {{ data?.email }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </v-select>
              </ValidationProvider>
            </b-form-group>

            <b-form-group
              label="Notice Description"
              label-for="description"
              class="required-label"
            >
              <validation-provider
                name="description"
                vid="description"
                v-slot="{ errors }"
              >
                <div :class="{ fullscreen: isFullscreen }">
                  <quill-editor
                    v-model="description"
                    :options="editorOption"
                    :style="editorStyle"
                    class="custom-quill-editor"
                  >
                    <div id="toolbar" slot="toolbar">
                      <span class="ql-formats">
                        <button class="ql-bold">Bold</button>
                        <button class="ql-italic">Italic</button>
                        <button class="ql-underline">Underline</button>
                        <button class="ql-strike">Strike</button>
                        <button class="ql-blockquote"></button>
                        <button class="ql-list" value="ordered"></button>
                        <button class="ql-list" value="bullet"></button>
                        <button class="ql-script" value="sub"></button>
                        <button class="ql-script" value="super"></button>
                        <button class="ql-indent" value="-1"></button>
                        <button class="ql-indent" value="+1"></button>
                        <button class="ql-direction" value="rtl"></button>
                        <button class="ql-align" value=""></button>
                        <button class="ql-align" value="center"></button>
                        <button class="ql-align" value="right"></button>
                        <button class="ql-align" value="justify"></button>
                        <select class="ql-color"></select>
                        <select class="ql-background"></select>
                        <select class="ql-size">
                          <option value="small"></option>
                          <option selected></option>
                          <option value="large"></option>
                          <option value="huge"></option>
                        </select>
                        <select class="ql-font"></select>
                        <select class="ql-header">
                          <option value="1">Heading 1</option>
                          <option value="2">Heading 2</option>
                          <option value="3">Heading 3</option>
                          <option value="4">Heading 4</option>
                          <option value="5">Heading 5</option>
                          <option value="6">Heading 6</option>
                          <option selected>Normal</option>
                        </select>
                        <button class="ql-link"></button>

                        <!-- Fullscreen Button -->
                        <button type="button" :title="isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'" class="fullscreen-btn" @click="toggleFullscreen">
                          🔳
                        </button>
                      </span>
                    </div>
                  </quill-editor>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- loading button -->
            <template v-if="isNoticeSendFromSubmit">
              <b-button class="float-right" variant="primary" disabled>
                <b-spinner small />
                Loading...
              </b-button>
            </template>

            <!-- submit button -->
            <template v-else>
              <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
              >
                Send Notice
              </b-button>
            </template>
          </b-form>
        </validation-observer>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BLink,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BRow,
  BCol,
  BFormTags,
  BFormDatepicker,
  VBTooltip,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { required, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";

import {
  NOTICE_CREATE,
  NOTICE_DETAILS_ACCESS,
} from "@/helpers/permissionsConstant";

export default {
  name: "NoticeView",
  components: {
    BCardActions,
    BForm,
    BButton,
    BCard,
    BLink,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    quillEditor,
    BRow,
    BCol,
    BFormTags,
    flatPickr,
    BFormDatepicker,
  },

  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      editorStyle: {
        minHeight: "13rem", // Min height for the editor
        overflowY: "auto",  // Enable scroll if content overflows
      },
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },

      NOTICE_CREATE,
      NOTICE_DETAILS_ACCESS,

      filterRangeDate: null,

      assetTypeId: "",
      modelType: "",
      searchInputType: "defined",
      title: "",
      description: "",
      noticeRecieverTypeValue: "",
      recievers: "",
      customRecievers: "",
      recieverIdOption: [],
      filterStartDate: "",
      filterEndDate: "",
      filterNoticeTypeValue: "",

      exceptEmployeeList: [],
      exceptEmployeeOptions: [],

      noticeRecieverOptoins: [
        {
          name: "Divisions",
          value: 1,
        },
        {
          name: "Division Heads",
          value: 2,
        },
        {
          name: "Departments",
          value: 3,
        },
        {
          name: "Departments Heads",
          value: 4,
        },
        {
          name: "Employees",
          value: 5,
        },
        {
          name: "Clients",
          value: 6,
        },
        {
          name: "Custom Receiver",
          value: 7,
        },
        {
          name: "All Employees",
          value: 8,
        },
      ],
      pageLength: 50,
      columns: [
        {
          label: "Title",
          field: "format_title",
          sortable: false,
        },
        {
          label: "Recipient",
          field: "format_type",
          sortable: false,
        },
        {
          label: "No. of Recipient",
          field: "format_total_notify_users",
          sortable: false,
        },
        {
          label: "Publish Date",
          field: "created_at",
          formatFn: this.formatDate,
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      params: [],
      rows: [],
      searchTerm: "",
      isLoading: false,
      isNoticeSendFromSubmit: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          // { field: "id", type: "desc" },
          // { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 50,
      },
      isFullscreen: false,
    };
  },

  watch: {
    // recievers
    noticeTypeId(oldVal, newVal) {
      if (oldVal != newVal) {
        this.recievers = "";
      }
    },
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  methods: {
    handleDateRangeChange() {
      if (!this.filterRangeDate || this.filterRangeDate === "") {
        // Handle case where dateRange is empty
        // this.filterStartDate = null;
        // this.filterEndDate = null;
      } else {
        const dates = this.filterRangeDate.split(" to ");

        if (dates.length === 1) {
          // Case where only one date is provided
          this.filterStartDate = dates[0];
          this.filterEndDate = dates[0];
        } else if (dates.length === 2) {
          // Case where both start and end dates are provided
          this.filterStartDate = dates[0];
          this.filterEndDate = dates[1];
        } else {
          // Handle invalid date range format
          console.error("Invalid date range format");
          // You can choose to handle this case differently based on your requirements
        }
      }
    },

    formatFnTableCreatedDate(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
    },
    showModal() {
      this.$bvModal.show("modal-notice-form");
    },

    hiddenModal() {
      this.$bvModal.hide("modal-notice-form");
      this.resetModal();
    },

    resetModal() {
      this.modelType = "";
      this.searchInputType = "defined";
      this.title = "";
      this.description = "";
      this.noticeRecieverTypeValue = "";
      this.recievers = "";
      this.customRecievers = "";
      this.recieverIdOption = [];
    },

    onShowPage(id) {
      this.$router.push({
        name: "admin-notice-details",
        params: { id },
      });
    },

    filterByDate() {
      this.loadItems();
    },
    filterNoticeByType() {
      this.loadItems();
    },
    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    async setSelectedRecieverType(noticeTypeId) {
      let res;

      if (noticeTypeId == null) {
        this.recieverIdOption = [];
      }

      this.searchInputType = "defined";

      if (noticeTypeId === 1) {
        res = await this.$api.get("api/divisions/all");
        let divisions = res;
        this.recieverIdOption = (divisions?.data?.data || []).map((item) => {
          let name = item?.name;
          return {
            name,
            id: item.id,
          };
        });
      } else if (noticeTypeId === 2) {
        res = await this.$api.get("api/divisions?include=head");
        let divisions = res;
        let emailSet = new Set();

        this.recieverIdOption = (divisions?.data?.data || []).reduce(
          (acc, item) => {
            let email = item?.head?.data?.email;

            if (email && !emailSet.has(email)) {
              emailSet.add(email);
              let name = `${item?.head?.data?.name} (${email})`;

              acc.push({
                name,
                id: item.head_id,
              });
            }

            return acc;
          },
          []
        );
      } else if (noticeTypeId === 3) {
        res = await this.$api.get("api/departments");

        let departments = res;
        this.recieverIdOption = (departments?.data?.data || []).map((item) => {
          let name = item?.name;
          return {
            name,
            id: item.id,
          };
        });
      } else if (noticeTypeId === 4) {
        res = await this.$api.get("api/departments?include=head");
        let departments = res;
        this.recieverIdOption = (departments?.data?.data || []).map((item) => {
          let name = `${item?.head?.data?.name} (${item?.head?.data?.email})`;

          return {
            name,
            id: item.head_id,
          };
        });
      } else if (noticeTypeId === 5) {
        res = await this.$api.get("api/users/active-all");
        let users = res;
        this.recieverIdOption = (users?.data?.data || []).map((item) => {
          let name = `${item?.name} (${item?.email})`;

          return {
            name,
            id: item.id,
          };
        });
      } else if (noticeTypeId === 6) {
        res = await this.$api.get("api/leads/all");
        let leads = res;
        this.recieverIdOption = (leads?.data?.data || []).map((item) => {
          let name = item?.email;

          return {
            name,
            id: item.id,
          };
        });
      } else if (noticeTypeId === 7) {
        this.searchInputType = "custom";
      } else {
        this.searchInputType = "all";
      }
    },
    async onDelete(id) {
      try {
        await this.$api.delete(`/api/asset-types/${id}`);

        //   this.loadItems();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Asset Types Successfully Deleted",
          },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },
    onSortChange(params) {
      this.params = (params || []).map((item) => {
        let field = item.field;
        return {
          field: field,
          type: item.type,
        };
      });

      this.updateParams({
        sort: this.params,
      });
      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    async getNotices(params) {
      return await this.$api.get("api/notices", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
          filterStartDate: params.filterStartDate,
          filterEndDate: params.filterEndDate,
          filterNoticeType: params.filterNoticeType,
        },
      });
    },

    async getEmployees() {
      return await this.$api.get("api/users/active-all");
    },
    async loadItems() {
      try {
        this.handleDateRangeChange();
        this.getEmployees();
        const [notices, employee] = await Promise.all([
          this.getNotices({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
            filterStartDate: this.filterStartDate,
            filterEndDate: this.filterEndDate,
            filterNoticeType: this.filterNoticeTypeValue,
          }),
          this.getEmployees(), // Add this line to ensure employee data is fetched
        ]);

        const data = notices?.data?.data;
        this.rows = data;

        this.exceptEmployeeOptions = (employee?.data?.data || []).map((item) => {
            return {
              avatar: item?.avatar,
              name: item?.name,
              email: item?.email,
              mobile: item?.mobile,
              employee_number: item?.employee_number,
              id: item?.id,
              departmentId: item?.department_id,
            }
          });
      
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.noticeValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.isNoticeSendFromSubmit = true;

            await this.$api.post("/api/notices", {
              type: this.noticeRecieverTypeValue,
              title: this.title,
              description: this.description,
              type_items: this.recievers,
              custom_items: this.customRecievers,
              except_employee: this.exceptEmployeeList,
            });
            this.isNoticeSendFromSubmit = false;
            this.hiddenModal();

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Notice Sent Successfully",
              },
            });
          } catch (error) {
            this.isNoticeSendFromSubmit = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.noticeValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.ql-editor .ql-font-timesnewroman {
  font-family: "Times New Roman", Times, serif;
}
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/quill.scss";
.ql-editor {
  transition: height 0.2s ease;
  min-height: 13rem;
}

/* Fullscreen mode */
.fullscreen {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 9999;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

/* Adjust editor and toolbar in fullscreen mode */
.fullscreen .ql-container {
  height: calc(100vh - 50px) !important;
}

.fullscreen .ql-toolbar {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1000;
  padding: 10px;
}

/* Fullscreen button styling */
.fullscreen-btn {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 18px;
  margin-left: 10px;
}
</style>

